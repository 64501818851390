/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Exams. Need I say more. Everyone gets nervous for exams, it’s completely normal and that’s what I was always told when I expressed my nerves before exams. I would worry about them long before they happened, whilst they were happening and after I’d sat them. I dreaded the walk around the hall attempting to find my name on a desk. As they usually let you in from A-Z and my initial comes towards the end of the alphabet, it also meant trying to find my seat with lots of people already sitting down and watching me (and I assumed judging me at my inability to find my name on a desk)."), "\n", React.createElement(_components.p, null, React.createElement("ins", {
    class: "adsbygoogle",
    "data-ad-layout": "in-article",
    "data-ad-format": "fluid",
    "data-ad-client": "ca-pub-1001229702754753",
    "data-ad-slot": "8446829758"
  }), " "), "\n", React.createElement(_components.p, null, "In sixth-form in particular, I felt physically ill before exams. This is when I first thought something else was going on - exams might make people nervous, but not physically ill? I remember sitting on the bus on the way to my Spanish exam: sweating, heart racing, palpitations, headache, nausea and feeling faint. I’d practised my oral exam and memorised everything I was going to say (FYI – NOT how to learn a new language), but as soon as I was in the exam, I’d forgotten everything (including how to breathe properly). "), "\n", React.createElement(_components.p, null, "I’d mentioned it to my parents and they’d suggested we visit a hypnotherapist (I didn’t). I told my teacher and basically got an ‘oh dear’.  I also went to the doctors and explained what was going on. I didn’t get a diagnosis, but I did get a doctors note so I could sit my exams in a smaller, less daunting room. This felt good enough at the time but again cemented the idea in my brain that it was normal to feel that way and just part of my personality that I’d have to deal with. Additionally, as I’d presented my college with a doctors note, they made me attend a relaxation session where they taught us breathing techniques and sprayed the room with lavender (a nice sentiment but I can’t say it helped). "), "\n", React.createElement("ins", {
    class: "adsbygoogle",
    "data-ad-layout": "in-article",
    "data-ad-format": "fluid",
    "data-ad-client": "ca-pub-1001229702754753",
    "data-ad-slot": "8446829758"
  }), "\n", React.createElement(_components.p, null, "For my A level law exam I was expecting to be in a smaller room, but I wasn’t told where to go. I attended the main exam hall where the invigilators also couldn’t find out where I was meant to be, so they sat me at the front of the exam hall and found me a spare paper. I think the theory was so I couldn’t see everyone in the hall, however it made it a LOT worse that I wasn’t supposed to be there, had 100s of people watching as they sat me in a spare seat at the front of the hall and was only just filling out the front of the paper with my name when everyone else was about to start. I was in a state of panic and couldn’t concentrate the whole exam (for anyone who’s ever sat a law exam, you’ll know there is no time for thinking). All I could think about was the hundreds of people staring at the back of my head. What if I get told off for being in the wrong place? Do the invigilators hate me now for causing a fuss? Is everyone else thinking how stupid I must be? I could see them talking amongst each other and (what I know now) communicating with staff outside the exam hall about the mix-up on their walky-talkies. It turned out I should have been in a smaller room for the exam, but the message didn’t get to me and the invigilators only found out mid-exam."), "\n", React.createElement(_components.p, null, "I was predicted a B in that law exam, but I came out with an E. My grade was appealed on the basis that it was so far from what I was capable of, but the appeal was rejected. I don’t think I need to explain how crap this made me feel. I re-sat the exam a couple months later, this time in a smaller room. I did no extra revision for the exam and I achieved a couple marks off an A. Whilst I would still panic and get nervous, it significantly helped being in a room of 20 people or less and allowed me to concentrate a lot better. "), "\n", React.createElement("ins", {
    class: "adsbygoogle",
    "data-ad-layout": "in-article",
    "data-ad-format": "fluid",
    "data-ad-client": "ca-pub-1001229702754753",
    "data-ad-slot": "8446829758"
  }), "\n", React.createElement(_components.p, null, "Students would sometimes be jealous of the people in the smaller rooms and/or getting extra time (which I didn’t get), like they were getting special treatment, like for some reason it makes the exam content any easier and that they were the ones at a disadvantage. In reality, I was jealous of them for not needing to be segregated from everyone else in a smaller room, for their ability to not feel physically ill when sitting an exam. When I went to uni I think this stopped me asking for special measures for my exams and presentations etc. Please never feel this way, always ask for help if you need it."), "\n", React.createElement(_components.p, null, "I hope this story resonates with someone and I haven’t just waffled on. Please, if you’re struggling in the main exam hall or with the thought of exams, speak to your school/college/uni (and/or Dr) and see what they can do for you."), "\n", React.createElement(_components.p, null, "Sarah from Anxious Extrovertx"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
